import React, { useState } from 'react';

const ComponentName = () => {        
    return (
        <section className="py-12 bg-white sm:py-16 lg:py-20">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
            <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl font-pj">Coach that will become your best friend</h2>
            <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-8 font-pj">
		A dating coach that possesses several key characteristics to effectively help individuals navigate the complexities of dating and relationships.
	    </p>
        </div>

        <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-24">
            <div className="md:p-8 lg:p-14">
                <h3 className="mt-12 text-2xl font-bold text-[#DD2E44] font-pj">Empathetic & Patient</h3>
                <p className="mt-5 text-base text-gray-600 font-pj">
		 Makes people feel heard and supported. Exhibit patience, understanding that progress in dating can take time and setbacks are part of the process.
		</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
                <h3 className="mt-12 text-2xl font-bold text-[#DD2E44] font-pj">Problem-Solver</h3>
                <p className="mt-5 text-base text-gray-600 font-pj">
			Identifies and addresses obstacles in people's dating lives through practical solutions and strategies
		</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
                <h3 className="mt-12 text-2xl font-bold text-[#DD2E44] font-pj">Knowledgeable</h3>
                <p className="mt-5 text-base text-gray-600 font-pj">
			Has a deep understanding of dating dynamics, relationship psychology, and social interactions. Tailors advice and strategies to fit the unique needs and circumstances of each person.
		</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-t md:border-gray-200">
                <h3 className="mt-12 text-2xl font-bold text-[#DD2E44] font-pj">Non-judgmental</h3>
                <p className="mt-5 text-base text-gray-600 font-pj">
			Provides a safe space for people to express themselves without fear of being judged. Possesses excellent communication skills to effectively convey advice and understand concerns.
	       </p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
                <h3 className="mt-12 text-2xl font-bold text-[#DD2E44] font-pj">Motivational</h3>
                <p className="mt-5 text-base text-gray-600 font-pj">
			Inspires and encourages people to step out of their comfort zones and build confidence. Maintains a positive and optimistic outlook, helping people stay motivated and hopeful.
		</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
                <h3 className="mt-12 text-2xl font-bold text-[#DD2E44] font-pj">Ethical & Confidential</h3>
                <p className="mt-5 text-base text-gray-600 font-pj">
			Adheres to a strong code of ethics, offering honest and respectful guidance. Respects privacy and ensures that all personal information shared is kept confidential.
		</p>
            </div>
        </div>
    </div>
</section>

    )
}
export default ComponentName;
