import AppScreenshot from '../assets/AppScreenshot.png'
import ProductHunt from './ProductHunt.js';
export default function Example() {

  return (
    <div className="bg-gray-900">

      <div className="relative isolate pt-14">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
        </div>
        <div className="py-24 sm:py-32 lg:pb-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
	     <ProductHunt/>
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                AI Powered Dating & Relationship Coach
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
		Our AI provides answers to your questions without judgment or bias. From simple queries to personal or sensitive topics,feel free to explore and seek information without fear of criticism or bias.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="https://chat.loveadvisor.ai"
                  className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
		  style={{background: "#DD2E44"}}
                >
                  Get started
                </a>
              </div>
            </div>
            <img
	      src={AppScreenshot}
              alt="App screenshot"
              width={2432}
              height={1442}
              className="mt-16 rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 sm:mt-24"
            />
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

