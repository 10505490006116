
const faqs = [
  {
    id: 1,
    question: "Why should I use AI powered dating coach?",
    answer: "Because humans are not available 24*7, but the questions on mind haunt you at any time. Because you can't ask everything to humans, even to your closest friends. Because humans are biased and limited to their own experiences and viewpoints. AI dating coaches are unbiased, available 24*7 and keep your information confidential"
  },
 
  { 
    id: 2,
    question: "What kind of questions I can ask to AI dating coach?",
    answer: "From simple queries to personal or sensitive topics,feel free to explore and seek information without fear of criticism or bias."
  },
  
  { 
    id: 3,
    question: "Is my data safe?",
    answer: "Yes, your data is absolutely safe" 
  },
 
]

export default function Example() {
  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
        <p className="mt-6 max-w-2xl text-base leading-7 text-gray-300">
          Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
          <a href="mailto:admin@graphia.ai" className="font-semibold text-indigo-400 hover:text-indigo-300">
            sending us an email
          </a>{' '}
          and we’ll get back to you as soon as we can.
        </p>
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base font-semibold leading-7 text-white">{faq.question}</dt>
                <dd className="mt-2 text-base leading-7 text-gray-300">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

