
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import HeroSection from './components/HeroSection.js';
import Feature from './components/Feature1.js';
import Pricing from './components/Pricing.js';
import FAQs from './components/FAQs.js';


const LandingPage = () => {
  return (
   <>
   <Header/>
   <HeroSection/>
   <Feature/>
   <Pricing/>
   <FAQs/>
   <ElaborateFooter/>
   </>
  );
};

export default LandingPage;

