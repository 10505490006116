import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../assets/loveadvisor.svg';


export default function Example() {

  return (
    <div className="bg-gray-900">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">LoveAdvisor AI</span>
              <img
		src={logo}
                className="h-8 w-auto"
                alt="LoveAdvisor AI"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="https://chat.loveadvisor.ai" className="text-xl font-semibold leading-6 text-white">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
      </header>
   </div>
  )
}

